import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const NotFoundPage = ({ data }) => (
  <Layout overlayHeader={true}>
    <SEO title="404: Not found" />
    <Hero
      title="Something went wrong"
      subTitle="Page not found"
      image={data.hero}
    />
  </Layout>
)

export default NotFoundPage

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "aurora5.jpg" }) {
      colors {
        ...GatsbyImageColors
      }
      childImageSharp {
        fluid(maxWidth: 1440, quality: 60) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
